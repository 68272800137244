import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Excerpt from "../components/excerpt"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPost(filter: {status: {eq: "publish"}}, sort: {order: DESC, fields: date}, limit:10) {
        edges {
          node {
            id
            wordpress_id
            title
            slug
            author {
              avatar_urls {
                wordpress_48
              }
              wordpress_id
              name
              slug
            }
            content
            date
            excerpt
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      {/* <h1>Home</h1> */}
      <div className="index-title">
        LATEST POSTS
      </div>
      {
        data.allWordpressPost.edges.map(edge => (
          <Excerpt post={edge.node} />
        ))
      }
    </Layout>
  )
};

export default IndexPage
